import './app.scss';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Storage } from 'react-jhipster';
import { Switch, useLocation, useHistory, Route } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
  AUTHORITIES,
  QUICKSIGHT_WL_USAGE_REPORT_ID,
  CALLBACK_PATH,
  ENABLE_DYNAMIC_WL_TENANT_STYLING,
  TENANT_STATIC_WEB_RESOURCES_ROOT_URL,
  IMPERSONATION_ROLES,
} from 'app/config/constants';
import { hasAnyRole } from 'app/modules/services/security';
import { searchAccountsByAccountId } from 'app/modules/services/serverAPI';
import { OnTenantSelection } from 'app/modules/pages/landing-home/data/actions';
import { OnSearchSuccess } from 'app/modules/pages/user-search/data/actions';
import { IRootState } from 'app/shared/reducers';
import { DualListBox } from 'app/modules/components/listBox/dualList';
import { IpList } from 'app/modules/components/ipList/ipList';
import { AuthRoute } from 'app/authRoute';
import PageNotFound from 'app/shared/error/page-not-found';
import Notification from 'app/shared/layout/notification/notification';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import NewHeader from 'app/newHeader';
import NewFooter from 'app/newFooter';
import * as userSearchPageActions from 'app/modules/pages/user-search/data/actions';
import LoaderSpinner from 'app/shared/layout/spinner/spinner';
import { SearchView } from 'app/modules/pages/user-search/searchview/searchview';
import {
  getCGEntitlementsData,
  getGlobalOktaAuthLoader,
  getIsMaeApp,
  setAccountId,
  setCurrentUser,
  setDelegationPath,
  setGlobalOktaAuthLoader,
  setIsMaeApp,
  setSelectedTenant,
} from './shared/reducers/base-user.reducer';
import isEmpty from 'lodash/isEmpty';
import { accountIdsWithUserAdminRole, getUserAdminRedirectUrl, validateRoles } from './shared/util/common-utils';
import { PaginationState } from '@tanstack/react-table';

const Home = React.lazy(() => import('app/home-container'));
const IPScreen = React.lazy(() => import('app/modules/pages/ip/ipscreen-container'));
const MainPageContainer = React.lazy(() => import('app/modules/pages/main/mainpage-container'));
const AccountSearchContainer = React.lazy(() => import('app/modules/pages/accountSearch/accountSearch-container'));
const UsageReport = React.lazy(() => import('app/modules/pages/reports/usageReport'));
const StocksReportContainer = React.lazy(() => import('app/modules/pages/stocks/stockReport'));
const UserSearchContainer = React.lazy(() => import('app/modules/pages/user-search/userSearchPage'));
const IdentityDelegationPage = React.lazy(() => import('app/modules/pages/identityDelegation/IdentityDelegationPage'));

const OKTA_ROLES = [AUTHORITIES.SUPER_ADMIN, AUTHORITIES.READONLY_ADMIN];

const AppRoutes = ({ authClient, tenant }) => {
  const dispatch = useDispatch();

  const isPlatformAdmin = hasAnyRole(OKTA_ROLES);
  const location = useLocation();
  const history = useHistory();

  const searchValue = useSelector((state: IRootState) => state.entities.pages.searchPage.userSearchParams.searchValue);
  const searchLabel = useSelector((state: IRootState) => state.entities.pages.searchPage.userSearchParams.searchLabel);
  const notification = useSelector((state: IRootState) => state.notificationReducer);
  const selectedTenant = useSelector((state: IRootState) => state.entities.pages.tenantSelectionPage.selectedTenant);
  const globalOktaAuthLoader = useSelector(getGlobalOktaAuthLoader);
  const isMaeApp = useSelector(getIsMaeApp);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const onSearch = (selections: any) => {
    dispatch(userSearchPageActions.OnUserSearchValueUpdate({ searchValue: selections.value, searchLabel: selections.item.value }));
    if (selections?.reinitiate) {
      setPagination({ pageIndex: 0, pageSize: 10 });
      dispatch(userSearchPageActions.OnSearch({ ...selections, pagination: { pageIndex: 1, pageSize: 10 } }));
    } else {
      dispatch(userSearchPageActions.OnSearch({ ...selections, pagination: { pageIndex: pageIndex + 1, pageSize } }));
    }

    if (location.pathname !== '/search') {
      history.push({
        pathname: '/search',
        state: { isBackFromMain: true, selections },
      });
    }
  };

  useEffect(() => {
    const redirectToLogin = (): void => {
      window.location.replace(`${tenant.loginURL + btoa(window.location.href)}&newApproach=true`);
      return;
    };

    if (sessionStorage.getItem('basePath')) {
      // This will redirect the User to the account URL instead of base URL, overriding the
      // restoreOriginalUri above.
      authClient.setOriginalUri(sessionStorage.getItem('basePath'));
    }

    if (window.location.hash && !window.location.pathname.includes(CALLBACK_PATH)) {
      const sessionToken = window.location.href.split('#')[1];
      authClient.signInWithRedirect({
        sessionToken,
        prompt: 'none',
      });

      return;
    }

    authClient.authStateManager.subscribe(async authState => {
      // handle the latest evaluated authState,
      // like integrate with client framework's state management store
      if (authClient.authStateManager.getPreviousAuthState() === null) {
        const authSession = await authClient.session.get();
        if (authSession.status !== 'ACTIVE') {
          redirectToLogin();
          return;
        }

        if (
          authSession.status === 'ACTIVE' &&
          (authClient.authStateManager.getAuthState() === null || !authClient?.authStateManager?.getAuthState()?.isAuthenticated)
        ) {
          if (!window.location.pathname.includes(CALLBACK_PATH)) {
            authClient.signInWithRedirect({
              prompt: 'none',
            });
            return;
          }
        }
      }
      if (authState?.isAuthenticated) {
        dispatch(setAccountId(''));

        if (sessionStorage?.getItem('basePath')?.includes('identitydelegation')) {
          dispatch(setDelegationPath(sessionStorage?.getItem('basePath')));
          dispatch(setSelectedTenant(tenant));
          // Removing the basePath once its stored in redux so that we don't rerun the logic again.
          sessionStorage.removeItem('basePath');
          const oktaUser = await authClient.token.getUserInfo();
          dispatch(setCurrentUser(oktaUser));
          dispatch(setIsMaeApp(true));
          dispatch(setGlobalOktaAuthLoader(false));
          return;
        }
        // get the accountId if requested in the first visit.
        const basePath = sessionStorage.getItem('basePath')?.split('#')[0]?.split('/');
        let accountIdRequested = basePath?.[basePath?.length - 1];

        dispatch(setAccountId(accountIdRequested));

        const oktaUser = await authClient.token.getUserInfo();
        dispatch(setCurrentUser(oktaUser));

        // Removing the basePath once its stored in redux so that we don't rerun the logic again.
        sessionStorage.removeItem('basePath');

        const redirect = () => history.push(getUserAdminRedirectUrl(oktaUser.MAETenantID, accountIdRequested, oktaUser.MAEUserID));

        if (validateRoles(OKTA_ROLES, oktaUser?.roles) && accountIdRequested) {
          redirect();
        }
        // Only execute if its not a super admin or read only admin.
        // When the app knows its not a SUPER or READONLY admin, get the role details as below.
        if (!validateRoles(OKTA_ROLES, oktaUser?.roles)) {
          // When the app knows its not a SUPER or READONLY admin, get the role details as below.
          if (JSON.parse(sessionStorage.getItem('MAERoles'))?.[0] !== AUTHORITIES.MAE_USER_ADMIN) {
            const fetchRoles = async () => {
              try {
                // Disabling ACCOUNT_ADMIN Roles.
                const role = AUTHORITIES.MAE_USER_ADMIN;

                // DONE store this value and persist it in the cache.
                const result = await dispatch(
                  getCGEntitlementsData({
                    MAEUserID: oktaUser?.MAEUserID,
                    MAETenantID: oktaUser?.MAETenantID,
                    accountId: accountIdRequested,
                  })
                ).unwrap();

                const accountIds = accountIdsWithUserAdminRole(result.data.responseData.value);

                dispatch(setSelectedTenant(tenant));

                // Adjusting the logic to handle MAE_USER_ADMIN without a proper redirect URL or if they have
                // multiple accounts associated.
                if (accountIds.length > 0) {
                  Storage.session.set('MAERoles', [role]);
                  if (accountIds.length <= 1) {
                    accountIdRequested = accountIds[0];
                    if (!accountIdRequested) {
                      dispatch(OnTenantSelection({ tenantId: oktaUser.MAETenantID, tenantName: oktaUser.TenantCode }));
                      const customers = await Promise.all(
                        accountIds.map(async id => {
                          const { data } = await searchAccountsByAccountId(oktaUser.MAETenantID, id, 1, 75);
                          return data.responseData.content[0];
                        })
                      );
                      dispatch(OnSearchSuccess({ users: [], customers, columnsConfig: 0, emailSearchResult: [], ip: [] }));
                    } else {
                      redirect();
                    }
                  }
                } else {
                  history.push('/');
                }
              } catch (error) {
                console.error(error);
              }
            };

            if (validateRoles(OKTA_ROLES, oktaUser?.roles) || accountIdRequested) {
              Storage.session.remove('MAERoles');
            }

            // Function invoked only when the user is a
            // 1. oktauser,
            // 2. is Not a platform-admin
            // 3. Has not set the MAERoles in the session storage.
            if (oktaUser && !validateRoles(OKTA_ROLES, oktaUser?.roles) && !Storage.session.get('MAERoles')) {
              fetchRoles();
            }
          }
        }

        dispatch(setGlobalOktaAuthLoader(false));
      }
    });
  }, [authClient, tenant.loginURL, dispatch, history, tenant]);

  useEffect(() => {
    const addTenantCSS = () => {
      const tenantPathKey = tenant.tenantCode?.toUpperCase();

      const tenantCssPath = `${TENANT_STATIC_WEB_RESOURCES_ROOT_URL}/assets/${tenantPathKey}/css/${tenantPathKey}.css`;

      const link = document.createElement('link');
      link.href = tenantCssPath;

      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.media = 'screen,print';

      document.getElementsByTagName('head')[0].appendChild(link);
    };
    if (ENABLE_DYNAMIC_WL_TENANT_STYLING === 'TRUE') {
      addTenantCSS();
    } else {
      // Will add a main selector when the toggle is OFF.
      document?.body?.classList?.add(`client-${tenant.tenantName.toLowerCase()}`);
    }
  }, [tenant.tenantCode, tenant.tenantName]);

  return (
    <div className="app-container d-flex flex-column vh-100">
      <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
        {isMaeApp && <NewHeader tenant={tenant} />}
        <SideBar />

        <div className="view-container flex-grow-1" id="app-view-container">
          {isMaeApp && (
            <div className="header-nav bg-primary">
              <div className="header-nav-inner">
                {hasAnyRole([AUTHORITIES.SUPER_ADMIN, AUTHORITIES.READONLY_ADMIN]) && !isEmpty(selectedTenant) && (
                  <SearchView onSearch={option => onSearch(option)} selectedOptionsProps={{ searchValue, searchLabel }} />
                )}
              </div>
            </div>
          )}

          <Suspense fallback={<LoaderSpinner customClass="suspense-callback" />}>
            <Switch>
              <AuthRoute path="/" exact={true} component={Home} />
              <Route path="/implicit/callback" exact component={LoginCallback} />
              <AuthRoute path="/ipscreen" component={IPScreen} hasAnyAuthorities={OKTA_ROLES} />
              <AuthRoute path="/list" component={DualListBox} hasAnyAuthorities={OKTA_ROLES} />
              <AuthRoute path="/IpList" component={IpList} hasAnyAuthorities={OKTA_ROLES} />
              <AuthRoute path="/stocks" component={StocksReportContainer} hasAnyAuthorities={OKTA_ROLES} />
              <AuthRoute
                path="/search"
                component={() => <UserSearchContainer pageIndex={pageIndex} pageSize={pageSize} setPagination={setPagination} />}
                hasAnyAuthorities={OKTA_ROLES}
              />
              <AuthRoute
                path="/main"
                component={MainPageContainer}
                hasAnyAuthorities={[...OKTA_ROLES, AUTHORITIES.MAE_USER_ADMIN, ...IMPERSONATION_ROLES]}
              />
              <AuthRoute path="/account/:accountId" exact component={() => <LoaderSpinner customClass="account-base" />} />
              <AuthRoute path="/tenantID/:tenantId/accountID/:accountId/UserID/:userId/view" exact component={AccountSearchContainer} />
              <AuthRoute
                path="/report/usageSummary"
                exact
                hasAnyAuthorities={OKTA_ROLES}
                component={() => <UsageReport reportID={QUICKSIGHT_WL_USAGE_REPORT_ID} onlyAccountNumber={false} />}
              />
              <AuthRoute
                path="/identitydelegation"
                exact
                component={() => <IdentityDelegationPage />}
                hasAnyAuthorities={[...IMPERSONATION_ROLES]}
              />
              <Route component={PageNotFound} />
            </Switch>
          </Suspense>
        </div>
        {isMaeApp && <NewFooter tenant={tenant} />}
        {notification.showNotification && <Notification />}
        {globalOktaAuthLoader && <LoaderSpinner customClass="app-routes-page" />}
      </Security>
    </div>
  );
};

export default React.memo(AppRoutes);
