import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createIdentityDelegationSession, getIdentityDelegationHistoryData } from 'app/modules/services/serverAPI';

const SLICE_NAME = 'pages/identityDelegation';

export type getHistoryPayload = {
  tenantId: string;
  userId: string;
  applicationCode: string;
  accountId: number;
};

export const createIdentityDelegation = createAsyncThunk(`${SLICE_NAME}/createIdentityDelegation`, async (payload: any) => {
  try {
    const result = await createIdentityDelegationSession(payload);
    return result;
  } catch (err) {
    return err;
  }
});

export const getIdentityDelegationHistory = createAsyncThunk(
  `${SLICE_NAME}/getIdentityDelegationHistory`,
  async (payload: getHistoryPayload) => {
    try {
      const result = await getIdentityDelegationHistoryData(payload.tenantId, payload.userId, payload.applicationCode, payload.accountId);
      return result?.data?.responseData?.value || [];
    } catch (err) {
      return err;
    }
  }
);

const initialState = {
  data: null as any,
  loading: false as boolean,
  historyData: null as any,
  isError: false as boolean,
  error: null as any,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanIdentityDelegation(state) {
      state.data = null;
    },
    cleanIdentityDelegationHistory(state) {
      state.historyData = null;
    },
  },
  extraReducers(builder) {
    return builder
      .addCase(createIdentityDelegation.fulfilled, (state, action) => {
        state.data = action.payload || null;
        state.loading = false;
      })
      .addCase(createIdentityDelegation.pending, state => {
        state.data = null;
        state.loading = true;
      })
      .addCase(createIdentityDelegation.rejected, state => {
        state.loading = false;
      })
      .addCase(getIdentityDelegationHistory.fulfilled, (state, action) => {
        state.historyData = action.payload || null;
        state.loading = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getIdentityDelegationHistory.pending, state => {
        state.historyData = null;
        state.loading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getIdentityDelegationHistory.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.payload || null;
      });
  },
});

// Selectors
export const getIdentityDelegationLoadingState = (state: any) => state.identityDelegation.loading;
export const getIdentityDelegationHistoryState = (state: any) => state.identityDelegation.historyData;
export const getIdentityDelegationHistoryError = (state: any) => state.identityDelegation.error;
export const getIdentityDelegationHistoryIsError = (state: any) => state.identityDelegation.isError;

// actions, reducers
export const reducer = slice.reducer;
export const { cleanIdentityDelegation, cleanIdentityDelegationHistory } = slice.actions;

// Type
export type IIdentityDelegationState = ReturnType<typeof slice.reducer>;
